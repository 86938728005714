import React, { useState } from 'react'
import leftarrow from "../assets/Moaisus-assets/Home/Bleeding Edge Tech/Left Arrow.png"
import rightarrow from "../assets/Moaisus-assets/Home/Bleeding Edge Tech/Right Arrow.png"
import Aws_Development from "../assets/moaisus-assets-webp/Aws - Development@2x.webp"
import JS_Development from "../assets/moaisus-assets-webp/JS - Development@2x.webp"
import Node_JS_Development from "../assets/moaisus-assets-webp/Node JS - Development@2x.webp"
import React_Development from "../assets/moaisus-assets-webp/React - Development@2x.webp"
import Flutter from "../assets/moaisus-assets-webp/Flutter@2x.webp"
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Illustrator_Design from "../assets/moaisus-assets-webp/Illustrator - Design@2x.webp"
import Figma from "../assets/moaisus-assets-webp/Figma - Design@2x.webp"
import XDDesign from "../assets/moaisus-assets-webp/XD - Design@2x.webp"
import Photoshop from "../assets/moaisus-assets-webp/Photoshop - Design@2x.webp"
import Facebook from "../assets/moaisus-assets-webp/Facebook - Marketing@2x.webp"
import AdSense from "../assets/moaisus-assets-webp/AdSense - Marketing@2x.webp"
import Youtube from "../assets/moaisus-assets-webp/Youtube - Marketing@2x.webp"
import Instagram from "../assets/moaisus-assets-webp/Instagram - Marketing@2x.webp"

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import '../css/Component.css';

const Tech = () => {

    const DesignArr = {
        step: 0,
        img: [
            { img: Illustrator_Design },
            { img: Figma },
            { img: XDDesign },
            { img: Photoshop },
        ]
    }

    const DevelopmentArr = {
        step: 1,
        img: [
            { img: React_Development },
            { img: Aws_Development },
            { img: Node_JS_Development },
            { img: JS_Development },
            { img: Flutter },
        ]
    }

    const MarketingArr = {
        step: 2,
        img: [
            { img: AdSense },
            { img: Facebook },
            { img: Instagram },
            { img: Youtube },
        ]
    }


    const [Swiper1, setSwiper] = useState()
    const [imgArr, setImgArr] = useState(DevelopmentArr)



    return (
        <div className='my-5 px-md-5 px-lg-5 px-xl-5 px-4 custom-padding'>
            <div className='d-flex align-items-center'>
                <h1 className='primaryColor m-0 mt-5 mx-4 mx-auto d-block mb-2'>Bleeding Edge Tech</h1>
            </div>

            <div className='mt-3 text-lg-center text-xl-center'>
                Businesses that acquire cutting-edge technologies have an advantage over competitors in the event that the technology later becomes widespread.In conclusion, bleeding edge technology refers to the most advanced and experimental technologies currently being developed. These technologies have the potential to revolutionize their respective fields, but they also come with their own set of challenges and risks. As such, it is important for society to carefully consider the potential benefits and drawbacks of these technologies before fully embracing them.
            </div>


            <div className="mt-5 grp-btn-border bet-cont mx-auto py-3">
                <div className="d-flex flex-row justify-content-around  btn-m">
                    <div className={imgArr.step === 0 ? "btn-txt-selected btn-txt fw-semibold" : "cursor btn-txt fw-semibold "} onClick={() => setImgArr(DesignArr)}>Design</div>
                    <div className=" grp-btn-border-right-line"></div>
                    <div className={imgArr.step === 1 ? "btn-txt-selected btn-txt fw-semibold" : "cursor btn-txt fw-semibold "} onClick={() => setImgArr(DevelopmentArr)}>Development</div>
                    <div className=" grp-btn-border-right-line"></div>
                    <div className={imgArr.step === 2 ? "btn-txt-selected btn-txt fw-semibold" : "cursor btn-txt fw-semibold "} onClick={() => setImgArr(MarketingArr)}>Marketing</div>
                </div>

            </div>
            <div className="d-flex flex-row justify-content-center mt-4 gap-3">

                <div className='d-flex  width-80'>
                    <Swiper
                        slidesPerView="auto"
                        spaceBetween={10}
                        modules={[Navigation]}
                        // navigation={true}
                        className="mySwiper3"
                        // centeredSlides={false}
                        loop={false}
                        onSwiper={(Swiper) => setSwiper(Swiper)}

                    >
                        {imgArr && imgArr.img.map((item, i) => (
                            <SwiperSlide key={i} className='' >
                                <div className="d-flex flex-column align-items-center bg-light betcard  p-3 rounded-4">
                                    <img src={item?.img} alt="bleeding tech images" width={100} height={100} className="img-thumbnail-res" />
                                </div>
                            </SwiperSlide >

                        ))}


                    </Swiper>
                </div>
            </div>

            <div className='d-flex flex-row justify-content-center mt-4 gap-5 mx-5'>
                <img src={leftarrow} alt="left-arrow" onClick={() => Swiper1?.slidePrev()} className="cursor" />
                <img src={rightarrow} alt="right-arrow" onClick={() => Swiper1?.slideNext()} className="cursor" />
            </div>
        </div >
    )
}

export default Tech