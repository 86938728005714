import React, { useEffect } from "react";
import "../css/Component.css";
import SocialMedia from "../commons/SocialMedia";
import Navbars from "../commons/Navbars";
import { Container } from "react-bootstrap";
import Footer from "../commons/Footer";


function Privacy() {

    useEffect(() => {
        window.scroll(0, 0)
    }, [])


    return (
        <div className="hide-scroll">
            <SocialMedia />
            <Navbars />
            <Container>
                <h2 className="privacy-heading" ><center>Privacy Policy</center></h2>
                <p className="mt-5 mx-3 mx-xl-0 mx-lg-0">


                    This Privacy Policy applies to <span className="fw-bold">MOAISUS GLOBAL SOLUTION</span> and describes how we collect, use, and share information about you when you use our websites, mobile apps, and other online products and services (collectively, the "Services") or when you otherwise interact with us.

                    By using the Services, you agree to the collection, use, and sharing of your information as described in this Privacy Policy. If you do not agree with our policies and practices, do not use the Services.

                    Collection of Information

                    We collect information about you in the following ways:

                    Information you provide to us: We collect information that you provide to us directly, such as when you create an account, make a purchase, or communicate with us. This information may include your name, email address, phone number, and other contact information.
                    Information we collect automatically: When you use the Services, we may automatically collect certain information about your device and your use of the Services. This information may include your IP address, device ID, browser type, and information about your use of the Services.
                    Use of Information

                    We use the information we collect to provide, maintain, protect, and improve the Services, and to develop new products and services. We also use this information to communicate with you, such as to send you updates or marketing materials.

                    Sharing of Information

                    We do not share your personal information with third parties for their own marketing purposes without your consent. We may share your personal information with third parties in the following circumstances:

                    With service providers: We may share your information with third party service providers who perform services on our behalf, such as hosting, payment processing, analytics, and customer support.
                    For legal reasons: We may disclose your information to third parties if we believe in good faith that such disclosure is necessary to comply with the law, to protect the rights or property of <span className="fw-bold">MOAISUS GLOBAL SOLUTION</span>, or to prevent harm to any person.
                    Data Retention

                    We retain personal information for as long as necessary to provide the Services and to comply with our legal obligations, resolve disputes, and enforce our policies.

                    Your Rights

                    You have the following rights in relation to your personal information:

                    The right to request access to your personal information
                    The right to request correction of your personal information
                    The right to request deletion of your personal information
                    The right to object to the processing of your personal information
                    The right to request that we restrict the processing of your personal information
                    The right to request the transfer of your personal information to another party
                    To exercise any of these rights, please contact us using the contact information provided below.

                    Data Security

                    We have implemented appropriate technical and organizational measures to protect your personal information against accidental or unlawful destruction, loss, alteration, unauthorized disclosure, or access.

                    Changes to this Privacy Policy

                    We may update this Privacy Policy from time to time. We will post any changes on this page and encourage you to review the Privacy Policy whenever you access the Services.

                    Contact Us

                    If you have any questions or concerns about this Privacy Policy, please contact us at [contact information].

                    <br />







                    Privacy Policy

                    This privacy policy ("Policy") explains how <span className="fw-bold">MOAISUS GLOBAL SOLUTION</span>("we", "us", or "our") collects, uses, and shares information about you when you use our websites, mobile apps, and other online products and services (collectively, the "Services").

                    Please read this Policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, do not use our Services. By accessing or using our Services, you agree to this Policy. This Policy may change from time to time. Your continued use of our Services after we make changes is deemed to be acceptance of those changes, so please check the Policy periodically for updates.

                    Information We Collect and How We Use It

                    We collect information about you in various ways when you use our Services.

                    Information you provide to us: We collect information that you provide to us directly, such as when you create an account, update your profile, post content, or communicate with us. This information may include your name, email address, phone number, and any other information you choose to provide.
                    Information we collect automatically: We also collect information about you automatically when you use our Services. This may include your IP address, device information, location data, and information about your activity on our Services. We use this information to provide and improve the Services, to personalize your experience, and for analytics and research purposes.
                    Information from third parties: We may also receive information about you from third parties, such as social media platforms, advertising partners, and analytics providers. We use this information to provide and improve the Services, to personalize your experience, and for analytics and research purposes.
                    We use the information we collect to:

                    Provide, maintain, and improve the Services
                    Personalize your experience
                    Communicate with you, including for customer service purposes
                    Analyze and understand how you use the Services
                    Develop new products and services
                    Promote the Services, including through targeted advertising
                    Sharing of Information

                    We may share your information in the following ways:

                    With third-party service providers: We may share your information with third parties that perform services on our behalf, such as hosting, analytics, payment processing, and customer service. These third parties are required to use your information only as necessary to perform these services on our behalf.
                    With business partners: We may share your information with our business partners for marketing, advertising, or other purposes.
                    For legal reasons: We may share your information if we believe it is necessary to comply with a legal obligation, such as a subpoena or court order, or to protect the safety, rights, or property of us or others.
                    With your consent: We may share your information if you have provided your consent, such as when you opt in to receive marketing communications from us or our partners.
                    Data Retention and Deletion

                    We will retain your information for as long as necessary to provide the Services to you, to comply with our legal obligations, and to resolve disputes. We may also retain certain information for a longer period of time for research or analytics purposes.

                    You may request that we delete your information at any time by contacting us using the contact information provided below. Please note that we may not be able to delete all of your information, as we may need to retain certain information for legal or other business purposes.

                    Data Security

                    We have implemented appropriate technical and organizational measures to protect your information from unauthorized
                </p>
            </Container>
            <Footer />
        </div>
    )
}

export default Privacy