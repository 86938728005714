import React from 'react'
import '../../css/Component.css';
import Backimg from "../../assets/Infra/infra.webp";
import downarrow from "../../assets/Moaisus-assets/About Us/Down Arrow.png";

function InfraHead() {
    return (
        <div className='about-container'>
            <img src={Backimg} alt="" className='back-img' />
            <div className='heading py-lg-4 px-lg-5 px-md-5 px-sm-5 py-md-3 mt-md-5 mt-3'>
                We deliver Borderless Innovation
            </div>

            <img src={downarrow} alt="" className='down-img ps-5' />

        </div>
    )
}

export default InfraHead