/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useState, useEffect } from "react";
import ReactReadMoreReadLess from "react-read-more-read-less";
import arrow from "../assets/Moaisus-assets/Headings Arrow.png";
import ltIcon from "../assets/Moaisus-assets/Home/LandingPage/ltpolygon.png";
import AI_ML_Projects from "../assets/Moaisus-assets/Home/What we do/AI_ML Projects.png";
import App_Development from "../assets/Moaisus-assets/Home/What we do/App Development.png";
import Corporate_Legal_Services from "../assets/Moaisus-assets/Home/What we do/Corporate Legal Services.png";
import Cyber_Security from "../assets/Moaisus-assets/Home/What we do/Cyber Security.png";
import DevOps from "../assets/Moaisus-assets/Home/What we do/DevOps.png";
import UI_UX_Design from "../assets/Moaisus-assets/Home/What we do/UI_UX Design.png";


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


const Whatwedo = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [windowWidth, setWindowWidth] = useState(windowDimensions.width);
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
      setWindowWidth(windowDimensions.width)
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener('resize', handleResize);

  }, [])
  const [showMore, setshowMore] = useState(false);
  // console.log(windowDimensions)
  const arr = [
    {
      img: Corporate_Legal_Services,
      heading: "Cloud Technologies",
      text: "Make the most of cloud and hybrid cloud solutions to boost business operations and revenues. Use our cloud knowledge to assure cloud readiness, create a practical cloud plan, ensure a seamless migration, and create cloud-native apps. Our team's integration skills can help you create smooth communication between on-premise and cloud apps, reduce interruption, and optimise your cloud infrastructures. ",
    },
    {
      img: Cyber_Security,
      heading: "Cyber Security",
      text: "Cybersecurity involves the protection of essential systems and sensitive data from digital attacks. It is also known as IT security and involves the use of measures to defend against threats to networked systems and applications, whether these threats come from within or outside of an organization. These measures are designed to prevent, detect, and respond to cyber threats and to protect against unauthorized access to or use of an organization's data and systems.",
    },
    {
      img: App_Development,
      heading: "App Development",
      text: "Create custom software applications that can be used to support a wide range of business processes and operations. These applications can help to automate and streamline many tasks, making them more efficient and effective. App development can also help organizations to create unique and engaging user experiences, which can help to improve customer satisfaction and loyalty. .",
    },
    {
      img: UI_UX_Design,
      heading: "UI/UX Design",
      text: "Overall, UI/UX design is important because it helps to create products and services that are easy and enjoyable for users to interact with. This can lead to increased customer satisfaction and loyalty, and can also have a positive impact on a business's bottom line.In addition to improving the user experience, good UI/UX design can also have a positive impact on a business. Well-designed products and services can help to increase customer satisfaction and loyalty, which can in turn lead to increased sales and revenue. ",
    },
    {
      img: DevOps,
      heading: "DevOps",
      text: "DevOps helps to improve the collaboration and communication between development and operations teams, which can lead to faster and more efficient delivery of software. This can help organizations to be more agile and responsive to the changing needs of their customers. Additionally, DevOps can help to reduce the risk of errors and improve the reliability and stability of the software being delivered. This can help to increase customer satisfaction and build trust in the organization.",
    },
    {
      img: AI_ML_Projects,
      heading: "AI/ML Projects",
      text: "AI and ML can be used to analyze large amounts of data, identify patterns and trends, and make predictions. This can help companies to make more informed decisions, improve their operations, and gain a competitive advantage. Additionally, AI and ML can be used to automate many tasks, freeing up employees to focus on more value-added work. This can help to improve efficiency and productivity. AI and ML can also be used to personalize customer experiences and improve customer satisfaction. ",
    },
    {
      img: Corporate_Legal_Services,
      heading: "Corporate Legal Services",
      text: "Corporate legal services can also help companies to negotiate and draft contracts, resolve disputes, and manage regulatory compliance. Additionally, corporate legal services can provide guidance on a variety of specialized legal topics, such as employment law, mergers and acquisitions, and corporate governance. Overall, corporate legal services can help companies to operate effectively and achieve their business objectives.",
    },
  ];


  return (
    <div className="her-bg-image rounded px-0 px-lg-5 px-md-5 px-lg-5">
      <div className="my-5 px-md-5 px-lg-5 px-xl-5 mx-5 py-5">
        <div className="d-flex align-items-center gap-3">
          <img src={arrow} alt="" />
          <h2 className="primaryColor">WHAT WE DO</h2>
        </div>
        <div className="mt-4 text-white text-justify">
          We improve the technologies you're already using and plan, create, and
          execute innovative solutions, abilities, and operations that release
          greater value for your enterprise by combining technical insight and
          ability with business expertise. Below are a few of our most popular
          platforms.
        </div>

        <div className="d-flex gap-3 text-white mt-4">
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2">
            {arr &&
              arr.map((item, i) => {
                if (windowWidth >= 576 ? (showMore || i <= 5) : (showMore || i <= 2)) {
                  return (
                    <div key={i} className="col-xl-4 py-3 ">
                      <div className="p-3 primary-color-border px-4 overflow-hidden">
                        <div className="d-flex justify-content-between align-items-center my-3 gap-4">
                          <img src={item?.img} alt="" width={65} height={65} />
                          <div className="line"></div>
                        </div>
                        <div className="primaryColor fw-bold">
                          {" "}
                          {item?.heading}
                        </div>
                        <div className="fw-lighter mt-2 text-white service-card-text text-justify">
                          <ReactReadMoreReadLess
                            charLimit={110}
                            readMoreText={"Read More"}
                            readLessText={"Read Less"}
                            readMoreClassName="read-more-less--more"
                            readLessClassName="read-more-less--less"
                          >
                            {item.text}
                          </ReactReadMoreReadLess>
                        </div>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
          </div>
        </div>
        <div className="d-flex justify-content-end mt-5">
          {showMore ? (
            <div
              onClick={() => setshowMore(false)}
              className="d-flex nv-letstalk ps-3 pe-5  btn-padding cursor"
            >
              View Less
              <img src={ltIcon} className="lticon" />
            </div>
          ) : (
            <div
              onClick={() => setshowMore(true)}
              className="d-flex nv-letstalk ps-3 pe-5  btn-padding cursor"
            >
              View More
              <img src={ltIcon} className="lticon" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Whatwedo;
