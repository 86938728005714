
import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";


// import required modules
import { Pagination, Autoplay } from "swiper";

const Testimonial = () => {
    return (
        <div style={{ marginTop: "-20%" }} className=' testimonialMain'>

            <div style={{ paddingTop: "70vh" }} >
                <h1 className="text-center primaryColor hide-scroll">What our client says?</h1>
            </div>
            <div style={{ paddingTop: "30vh" }}>
                <Swiper
                    slidesPerView={"auto"}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    centeredSlides={true}
                    pagination={true}
                    modules={[Pagination, Autoplay]}
                    className="mySwiper1"
                >
                    <SwiperSlide> Thank you Moaisus for your continued tireless efforts in making sure we're productive. You always delivered your task on time and meetd our expectations for business growth.The best quality is you always do it with a smile!!! Many thanks.
                        <br /><br />Niraj Kumar
                        <br />KIBA LABS - Founder
                    </SwiperSlide>

                </Swiper>
            </div>
        </div>
    )
}

export default Testimonial