import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutUs from "../Pages/AboutUs";
import Infra from "../Pages/Infra";
import Landingpage from "../Pages/LandingPage";
import Privacy from "../Pages/Privacy";
import "../css/Component.css";
import Navbars from "./Navbars";



function App() {
  return (
    <BrowserRouter>
      <Navbars />

      <Routes>
        <Route path="/" element={<Landingpage />} />
        <Route path="/" element={<Landingpage />} />
        <Route path="/infra" element={<Infra />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<Privacy id={"Privacy"} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
