import React from 'react'
import Carousel from './Carousel'

const Slider = () => {
  return (
    <div className='her-bg-image h-c-100 d-flex align-items-center pt-5'>
         <Carousel />
    </div>
  )
}

export default Slider