import React from 'react'
import '../css/Component.css';
import Backimg from "../assets/moaisus-assets-webp/Main Backgroun@2x.webp";
import downarrow from "../assets/Moaisus-assets/About Us/Down Arrow.png";

function AboutUsImage() {
    return (
        <div className='about-container'>
            <img src={Backimg} alt="" className='back-img' />
            <div className='heading py-lg-4 px-lg-5 px-md-5 px-sm-5 py-md-3 mt-md-5 mt-3'>
                If the vision doesn't motivate you it wasn't a vision in the first place
            </div>

            <img src={downarrow} alt="" className='down-img ps-5' />

        </div>
    )
}

export default AboutUsImage