import React from 'react'
import '../css/Component.css';
import headingimg from "../assets/images/Headings.png";

export default function OurTeam() {
    return (
        <div className='our-team-container mx-auto mx-5 mb-5' >
            <div className='about-heading pt-5 mx-5'>
                <div className='d-flex align-items-center'>
                    <img src={headingimg} alt="" />
                    <h2 className='primaryColor m-0 mx-4'>About Us</h2>
                </div>

                <p className='fw-bold mt-4'>
                    At Moaisus, we are passionate about creating innovative and high-quality products that help our customers solve their biggest challenges. Our team of experts brings together a unique combination of skills and experience to deliver solutions that are unmatched in the industry.

                    Our mission is to be the trusted partner for our customers, providing them with the support and guidance they need to succeed. We are committed to excellence and to delivering the best possible customer experience.

                    We invite you to learn more about our team, our products, and our commitment to our customers. Thank you for considering Moaisus as your partner in success.
                </p>
                <p className='fw-bold ch'>
                    By delivering vast expertise and sophisticated skills across a wide range of business and industrial processes, we will assist you in achieving higher growth and optimal performance. Our team brings together a unique set of skills in the areas of strategy, innovation management and efficiency, accounting and finance, compliance and risk management technology, and executive recruiting.

                    By collaborating with us, you will have access to the excellent expertise, talents, and solutions you require, while we assure the highest level of excellence at all times.
                </p>
            </div>

        </div>
    )
}

