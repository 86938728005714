import React from 'react'
import arrow from "../assets/Moaisus-assets/Headings Arrow.png"
import img1 from "../assets/moaisus-assets-webp/Image - 1@2x.webp"
import img2 from "../assets/moaisus-assets-webp/Image - 2.webp"
import img3 from "../assets/moaisus-assets-webp/Image2.webp"
import img4 from "../assets/moaisus-assets-webp/Image - 4.webp"

const Workhighlight = () => {
    return (
        <div id="work" className='my-5 px-lg-5 px-xl-5 px-3 custom-padding'>
            <div className='d-flex align-items-center'>
                <img src={arrow} alt="arrow" />
                <h2 className='primaryColor m-0 mx-4'>Work Highlight</h2>
            </div>

            <div className='mt-4 text-justify'>
                One of the key highlights of our work was successful and happy clients which has generated significant interest in the work methodology of the company. In addition to the achievements, there are more success stories building right now
            </div>

            <div className='mt-5'>
                <div className='highlight-container gap-2 gap-xl-4 gap-lg-4 '>
                    <div className='single'>
                        <img src={img1} alt="portfolio img 1" />
                    </div>
                    <div className='double d-flex pb-xl-1 pb-lg-1 flex-column  '>
                        <img src={img2} alt="portfolio img 2" />
                        <img src={img3} alt="portfolio img 3" />
                    </div>
                    <div className='third'>
                        <img src={img4} alt="portfolio img 4" />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Workhighlight