import React, { useEffect } from 'react';
import InfraContaint from '../commons/Infra/InfraContaint';
import InfraHead from '../commons/Infra/InfraHead';

function Infra() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div>
            <InfraHead />
            <InfraContaint />
        </div>
    )
}

export default Infra