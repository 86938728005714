import React from 'react'
import '../css/Component.css';
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Headings from "../assets/images/Headings.png";
import loyaltyimg from "../assets/moaisus-assets-webp/Custom Gift icon@2x.webp"
import docsecurtyimg from "../assets/moaisus-assets-webp/Document Security@2x.webp";
import botsimg from "../assets/moaisus-assets-webp/Chat Bots@2x.webp";
import customgiftsimg from "../assets/moaisus-assets-webp/CustomGiftIllustration@2x.webp"
import chatbotimg from "../assets/moaisus-assets-webp/ChatBotsIllustration@2x.webp";
import docillustrartion from "../assets/moaisus-assets-webp/DocumentSecurityIllustration@2x.webp"
function OurProducts() {
    return (
        <Tab.Container className="mt-5"
            id="left-tabs-example"
            defaultActiveKey="first"
        // activeKey="/home"
        // onSelect={selectedKey}
        >
            <Row className="justify-content-md-center mt-5 w-100 ms-0">
                <Col sm={4}>
                    <div className='ms-2 px-3 px-md-0 px-xl-0 px-lg-0'>
                        <h2 className="primaryColor">
                            <img src={Headings} alt="Headings" /> Our Products
                        </h2>
                    </div>
                    <Nav className='d-flex flex-row op-links align-items-center ps-3 mt-5 justify-content-center d-block d-sm-none flex-nowrap'>

                        <Nav.Link
                            eventKey="first" className='nav-link-border me-2 '
                        >
                            <div className="d-flex flex-column align-items-center ">
                                <img src={loyaltyimg} alt="loyaltyimg" className="box-img" />
                                <span className="h6 sp-text">
                                    Custom gift and  loyalty Programs
                                </span>
                            </div>
                        </Nav.Link>
                        <Nav.Link
                            eventKey="second" className='nav-link-border me-2  justify-content-center '
                        >
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <img src={docsecurtyimg} alt="doc security" className="box-img" />
                                <span className="h6 sp-text ">
                                    Document security  (Product)
                                </span>
                            </div>
                        </Nav.Link>
                        <Nav.Link
                            eventKey="third" className='nav-link-border me-2'
                        >
                            <div className="d-flex py-2 flex-column align-items-center">
                                <img src={botsimg} alt="bots img" className="box-img justify-content-center" />
                                <span className=" h6 sp-text">
                                    Chat<br />  Bots
                                </span>
                            </div>
                        </Nav.Link>

                    </Nav>
                    <Nav className="flex-column mt-5 d-none d-sm-block ">
                        <Nav.Item >
                            <Nav.Link
                                eventKey="first"
                                className='nav-link-border text-dark '
                            >
                                <div className="d-flex align-items-center">
                                    <img src={loyaltyimg} alt=" loyalty img" className="box-img" />
                                    <span className="m-3">
                                        Custom gift and <br /> loyalty Programs
                                    </span>
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second" className='nav-link-border text-dark mt-5'>
                                <div className="d-flex align-items-center">
                                    <img src={docsecurtyimg} alt=" docsecurity img" className="box-img" />
                                    <span className="m-3">
                                        Document security <br /> (Product)
                                    </span>
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="third" className='nav-link-border text-dark mt-5'>
                                <div className="d-flex align-items-center ">
                                    <img src={botsimg} alt="bots img" className="box-img" />
                                    <span className="m-3">
                                        Chat <br /> Bots
                                    </span>
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col sm={6} className="mt-5 justify-content-md-center">
                    <Tab.Content >
                        <Tab.Pane eventKey="first" className="mt-5 ">
                            {/* <div className="align-items-center m-auto d-flex"> */}
                            <img src={customgiftsimg}
                                width={"30%"}
                                alt="custom gifts illustration"
                                className='mx-auto d-block op-content-imgs'
                            />
                            <div className="m-3 text-md-center text-xl-center text-lg-center" >
                                Gift cards and loyalty programmes are popular ways to provide gift options or widespread incentives to customers. Our gift and loyalty frameworks are compatible with the majority of credit card and Smart cards, and we provide online reporting 24 hours a day, seven days a week.
                            </div>
                            {/* </div> */}
                        </Tab.Pane>
                        <Tab.Pane eventKey="second" className="mt-5 " >
                            {/* <div className="align-items-center m-auto"> */}
                            <img
                                src={docillustrartion}
                                width={"30%"}
                                alt="doc illustrartion"
                                className='mx-auto d-block op-content-imgs'
                            />
                            <div className="m-3 text-center">
                                A document management system's security measures aim to protect business data and interests, to comply with legal requirements such as privacy protection, and to prevent financial losses due to ID theft and fraud. Access to documents is generally restricted to ensure document security.
                            </div>
                            {/* </div> */}
                        </Tab.Pane>
                        <Tab.Pane eventKey="third" className="mt-5 ">
                            {/* <div className="align-items-center m-auto"> */}
                            <img
                                src={chatbotimg}
                                width={"30%"}
                                alt="chatbot illustration "
                                className='mx-auto d-block op-content-imgs'
                            />
                            <div className="m-3 text-center">
                                The use of chatbots has grown rapidly in recent years, and they are now used in a variety of industries, from e-commerce to finance. Many businesses use chatbots to provide instant customer support, answering common questions and directing customers to the appropriate resources. Other businesses use chatbots to provide personalized recommendations or to help customers complete transactions, such as booking a flight or making a purchase.
                            </div>
                            {/* </div> */}
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    )
}

export default OurProducts