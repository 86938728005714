import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "../css/contactus.css";
import Modal from "react-bootstrap/Modal";
import back from "../assets/Moaisus-assets/Contact Us/Back Icon Contact Us.png";
import { useFormik } from "formik";
import { signUpSchema } from "../schemas";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  select: "",
  text: "",
};

function ContactUs(props) {
  // for mail
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_em4fou8",
        "template_dbby3ok",
        form.current,
        "7qvk7Tgjm_s8LWAby"
      )
      .then(
        (result) => {
          console.log(result);
          toast.success("successfully submitted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        },
        (error) => {
          console.log(error.text);
          toast.warn("something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      );
  };

  // for validation

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: signUpSchema,
      onSubmit: (values, action) => {
        console.log(values);
        action.resetForm();
      },
    });

  return (
    <Modal
      {...props}
      size="lg"
      // aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal"
    >
      <div className="modal-head">
        <h3 className="first-text">Facing Problem?</h3>
        <h3 className="second-text">
          <b>Lets Get In Touch Now</b>
        </h3>
      </div>

      <Modal.Body>
        <form
          ref={form}
          className="login"
          onSubmit={(e) => {
            handleSubmit(e);
            sendEmail(e);
            // props.onHide(e);
          }}
        >
          <div className="outerdiv">
            <div className="flex">
              <div className="name-padding">
                <label htmlFor="" className="form-label">
                  First Name
                </label>
                <br />
                <input
                  type="text"
                  className="login-name all-inputs"
                  placeholder="First Name"
                  required
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.firstName && touched.firstName ? (
                  <p className="form-error">{errors.firstName}</p>
                ) : null}
              </div>

              <div className="name-padding">
                <label htmlFor="" className="form-label">
                  Last Name
                </label>
                <br />
                <input
                  type="text"
                  className="login-name all-inputs"
                  placeholder="Last Name"
                  required
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.lastName && touched.lastName ? (
                  <p className="form-error">{errors.lastName}</p>
                ) : null}
              </div>
            </div>

            <div className="email-padding">
              <label htmlFor="" className="form-label">
                Your Email Address
              </label>
              <br />
              <input
                type="email"
                className="login-email all-inputs"
                placeholder="Email Adress"
                name="email"
                required
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && touched.email ? (
                <p className="form-error">{errors.email}</p>
              ) : null}
            </div>

            <div className="email-padding">
              <label htmlFor="" className="form-label">
                Which Related Problem You Are Facing?
              </label>
              <br />
              <select
                className="login-email select all-inputs"
                name="select"
                required
                value={values.select}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="" disabled hidden className="form-option">
                  Select One
                </option>
                <option value="on screen problem" className="form-option">
                  on screen problem
                </option>
                <option value="off screen problem" className="form-option">
                  off screen problem
                </option>
                <option value="both" className="form-option">
                  both
                </option>
              </select>
              {errors.select && touched.select ? (
                <p className="form-error">{errors.select}</p>
              ) : null}
            </div>

            <div className="email-padding">
              <label htmlFor="" className="form-label">
                Type Your Message
              </label>
              <br />
              <textarea
                rows="4"
                cols="40"
                className="login-textarea all-inputs"
                placeholder="Lorem ipsum dolor sit amet."
                name="text"
                required
                value={values.text}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.text && touched.text ? (
                <p className="form-error">{errors.text}</p>
              ) : null}
            </div>

            <div className="button button-flex">
              <span className="d-flex cursor" onClick={props.onHide}>
                <img src={back} alt="" />
                <span className="back">Back</span>
              </span>

              <button
                className="submit button-text "
                type="submit"
                name="submit"
              >
                Let's Talk {">"}
              </button>
            </div>
            <ToastContainer />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default ContactUs;
