import React from 'react'
import '../css/Component.css';
import Row from "react-bootstrap/Row";
import headingimg from "../assets/images/Headings.png";
import leaseimg from "../assets/moaisus-assets-webp/Legalease@2x.webp";
import asfartrp from "../assets/moaisus-assets-webp/asfar trip@2x.webp";
import solutionlogo from "../assets/moaisus-assets-webp/IT99 Solutions Logo@2x.webp";
import avinya from "../assets/moaisus-assets-webp/Avinyaa@2x.webp";
import kibalogo from "../assets/moaisus-assets-webp/KIBA Labs Logo@2x.webp"
import cyberlogo from "../assets/moaisus-assets-webp/Cyber Security hive@2x.webp";
import merit from "../assets/moaisus-assets-webp/Merit@2x.webp"
import lawcompany from "../assets/moaisus-assets-webp/Law Company@2x.webp"
import ehdaya from "../assets/moaisus-assets-webp/Ehadaya@2x.webp"
import panchlogo from "../assets/moaisus-assets-webp/Panch tatva Bakers Logo@2x.webp"

const arr = [
    {
        card: {
            backgroundColor: "#fcf6e7",
            borderColor: "#fcf6e7",
            height: 130,
            borderRadius: 20,
        },
        img: {
            alt: "trusted partners image",
            src: leaseimg,
            sx: {
                display: "block",
                margin: "auto",
                marginRight: "auto",
                width: "70%",
            }
        },
        card2: {
            backgroundColor: "#fcf6e7",
            borderColor: "#fcf6e7",
            borderRadius: 20,
            height: 120
        }
    },
    {
        card: {
            backgroundColor: "#fdf0e6",
            borderColor: "#fdf0e6",
            height: 220,
            borderRadius: 20,
        },
        img: {
            alt: "trusted partners image",
            src: asfartrp,
            sx: {
                display: "block",
                margin: "auto",
                marginRight: "auto",
                width: "70%",
            }
        },
        card2: {
            backgroundColor: "#fdf0e6",
            borderColor: "#fdf0e6",
            borderRadius: 20,
            height: 120
        }
    },
    {
        card: {
            backgroundColor: "#eaedf3",
            borderColor: "#eaedf3",
            height: 130,
            borderRadius: 20,
        },
        img: {
            alt: "trusted partners image",
            src: kibalogo,
            sx: {
                display: "block",
                margin: "auto",
                marginRight: "auto",
                width: "70%",
            }
        },
        card2: {
            backgroundColor: "#eaedf3",
            borderColor: "#eaedf3",
            borderRadius: 20,
            height: 120
        }
    },
    {
        card: {
            backgroundColor: "#ebedef",
            borderColor: "#ebedef",
            height: 220,
            borderRadius: 20,
        },
        img: {
            alt: "trusted partners image",
            src: merit,
            sx: {
                display: "block",
                margin: "auto",
                marginRight: "auto",
                width: "70%",
            }
        },
        card2: {
            backgroundColor: "#ebedef",
            borderColor: "#ebedef",
            borderRadius: 20,
            height: 120
        }
    },
    {
        card: {
            backgroundColor: "#efe8ef",
            borderColor: "#efe8ef",
            height: 130,
            borderRadius: 20,
        },
        img: {
            alt: "trusted partners image",
            src: ehdaya,
            sx: {
                display: "block",
                margin: "auto",
                marginRight: "auto",
                width: "70%",
            }
        },
        card2: {
            backgroundColor: "#efe8ef",
            borderColor: "#efe8ef",
            borderRadius: 20,
            height: 120
        },
    },
    {
        card: {
            backgroundColor: "#e5fcf5",
            borderColor: "#e5fcf5",
            height: 220,
            borderRadius: 20,
            marginTop: 15
        },
        img: {
            alt: "trusted partners image",
            src: solutionlogo,
            sx: {
                display: "block",
                margin: "auto",
                marginRight: "auto",
                width: "70%",
            }
        },
        card2: {
            backgroundColor: "#e5fcf5",
            borderColor: "#e5fcf5",
            borderRadius: 20,
            height: 120
        }
    },

    {
        card: {
            backgroundColor: "#EAF8FC",
            borderColor: "#EAF8FC",
            height: 130,
            borderRadius: 20,
            marginTop: 15
        },
        img: {
            alt: "trusted partners image",
            src: avinya,
            sx: {
                display: "block",
                margin: "auto",
                marginRight: "auto",
                width: "70%",
            }
        },
        card2: {
            backgroundColor: "#EAF8FC",
            borderColor: "#EAF8FC",
            height: 120,
            borderRadius: 20,
        }

    },

    {
        card: {
            backgroundColor: "#e5f6f9",
            borderColor: "#e5f6f9",
            height: 220,
            borderRadius: 20,
            marginTop: 15

        },
        img: {
            alt: "trusted partners image",
            src: cyberlogo,
            sx: {
                display: "block",
                margin: "auto",
                marginRight: "auto",
                width: "70%",
            }
        },
        card2: {
            backgroundColor: "#e5f6f9",
            borderColor: "#e5f6f9",
            borderRadius: 20,
            height: 120
        }
    },

    {
        card: {
            backgroundColor: "#faf8f5",
            borderColor: "#faf8f5",
            height: 130,
            borderRadius: 20,
            marginTop: 15
        },
        img: {
            alt: "trusted partners image",
            src: lawcompany,
            sx: {
                display: "block",
                margin: "auto",
                marginRight: "auto",
                width: "70%",
            }
        },
        card2: {
            backgroundColor: "#faf8f5",
            borderColor: "#faf8f5",
            borderRadius: 20,
            height: 120
        }
    },

    {
        card: {
            backgroundColor: "#efe8ef",
            borderColor: "#efe8ef",
            height: 220,
            borderRadius: 20,
            marginTop: 15
        },
        img: {
            alt: "trusted partners image",
            src: panchlogo,
            sx: {
                display: "block",
                margin: "auto",
                marginRight: "auto",
                width: "70%",
            }
        },
        card2: {
            backgroundColor: "#efe8ef",
            borderColor: "#efe8ef",
            borderRadius: 20,
            height: 120
        }
    },
]
function trusted() {
    return (
        <div className='d-flex trusted-container'>
            <Row className='col-10  d-none d-md-block'>
                <Row sm={12}>
                    <Row className="my-5">
                        <div>
                            <h2 className="primaryColor">
                                <img src={headingimg} alt="trusted heading" /> Trusted By
                            </h2>
                        </div>
                    </Row>
                    <div className='row row-cols-5'>
                        {
                            arr.map((items, i) => {
                                if (i < 5) {
                                    return <div key={i} className='col'>
                                        <div className='card' style={{ ...items.card }}>
                                            <img src={items.img.src} alt={items.img.alt} style={{ ...items.img.sx }} />
                                        </div>

                                    </div>
                                }
                                else {
                                    if (i % 2 != 0) {
                                        return <div key={i} className='col minus-px'>
                                            <div className='card' style={{ ...items.card }}>
                                                <img src={items.img.src} alt={items.img.alt} style={{ ...items.img.sx }} />
                                            </div>

                                        </div>
                                    }
                                    else {
                                        return <div key={i} className='col '>
                                            <div className='card' style={{ ...items.card }}>
                                                <img src={items.img.src} alt={items.img.alt} style={{ ...items.img.sx }} />
                                            </div>

                                        </div>
                                    }
                                }


                            }
                            )

                        }
                    </div>

                </Row>
            </Row>

            <div className='trusted-md-cont d-md-content d-block d-md-none'>
                <Row className="my-5 ms-3">
                    <div>
                        <h2 className="primaryColor">
                            <img src={headingimg} alt="trusted heading" /> Trusted By
                        </h2>
                    </div>
                </Row>
                <div className='row row-cols-2 px-3'>

                    {
                        arr.map((items, i) => {
                            return <div key={i} className='col mdminus-px-even pb-3'>
                                <div className='card' style={{
                                    ...items.card2
                                }}>
                                    <img src={items?.img.src} alt={items.img.alt} style={{
                                        ...items.img.sx
                                    }} />
                                </div>
                            </div>

                        })
                    }
                </div>
            </div>

        </div>

    )
}

export default trusted