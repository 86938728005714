import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import Slider1 from "../assets/moaisus-assets-webp/Slider1.webp";
import Slider2 from "../assets/moaisus-assets-webp/ThreatScan Dashboard Mockup@2x.webp";
import Slider4 from "../assets/moaisus-assets-webp/slider4.webm";
import tablet from "../assets/moaisus-assets-webp/Mock video.webp";

// import required modules
import { EffectCoverflow, Pagination, Navigation, Autoplay } from "swiper";
import "../css/Component.css";

export default function Carousel() {


  return (
    <>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        initialSlide="1"
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        navigation={true}
        pagination={true}
        modules={[EffectCoverflow, Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="d-flex flex-column align-items-center">
            <h2 className="text-white mb-4">MyAsfar</h2>
            <img alt="asfar slider" width={800} src={Slider1} className="swslide" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="d-flex flex-column align-items-center">
            <h2 className="text-white mb-4">Welcome to</h2>
            <img alt="asfar slider" width={800} src={tablet} className='tablet-img swslide' />

            <video
              className="VideoTag swslide2 "
              width="760"
              autoPlay
              loop
              muted
            >
              <source alt="welcome to moaisus" src={Slider4} type="video/mp4" />
            </video>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="d-flex flex-column align-items-center">
            <h2 className="text-white mb-4">ThreatScan Dashboard</h2>
            <img alt="threatscan dashboard" width={800} className="swslide" src={Slider2} />
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
