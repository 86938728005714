import React, { useState } from "react";
import ltIcon from "../assets/Moaisus-assets/Home/LandingPage/ltpolygon.png";
import logo from "../assets/moaisus-assets-webp/Moaisus Logo MAX V2.webp";
import ContactUs from "./ContactUs";
import { Link } from "react-router-dom";

const list = [
  {
    name: "Portfolio",
    link: "/#portfolio",
    id: document.getElementById("work"),
  },
  {
    name: "About Us",
    link: "/about-us",
  },
  {
    name: "Infrastructure",
    link: "/infra",
  },
  {
    name: "Privacy Policy",
    link: "/privacy-policy",

  }
];

const Footer = () => {
  const [modalShow, setModalShow] = useState(false);


  return (
    <div className="bg-black pm-5 pt-5">
      <div className=" px-5 parent-footer custom-padding py-5">
        <div className="d-flex child-footer flex-row justify-content-between align-items-center">
          <div>
            <img src={logo} alt=" logo" className="img-footer" />
          </div>
          <div className="d-flex  justify-content-center letst alk-footer align-items-center w-80">
            <div
              className="d-flex nv-letstalk ps-3 pe-5  btn-padding cursor"
              onClick={() => setModalShow(!modalShow)}
            >
              Let's Talk
              <img src={ltIcon} alt="lets talk icon" className="lticon" />
            </div>
            <ContactUs show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </div>

        <div class="container pt-3 justify-content-center footerLink d-flex">
          {list.map((data, key) => {
            return (
              <Link key={key} to={`${data?.link}`} className="px-3 footer-link">
                {data?.name}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Footer;
