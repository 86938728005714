/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import ltIcon from "../assets/Moaisus-assets/Home/LandingPage/ltpolygon.png";
import logoimg2x from "../assets/moaisus-assets-webp/Moaisus Platinum Logo@2x.webp";
import "../css/Component.css";
import ContactUs from "./ContactUs";
const list = [
    {
        idno: 0,
        name: "Home",
        link: "/",
        bool: true
    },
    {
        idno: 1,
        name: "Portfolio",
        link: "/#portfolio",
        id: document.getElementById("work"),
        bool: false
    },
    {
        idno: 2,
        name: "About Us",
        link: "/about-us",
        bool: false
    },
    {
        idno: 3,
        name: "Infrastructure",
        link: "/infra",
        bool: false
    },
];

function Navbars() {

    const history = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [listbool, setListBool] = useState(list);

    const activenavlink = (e, key) => {
        const updatebools = listbool.map(x => {
            if (x.idno === key) {
                return { ...x, bool: true }
            }
            else {
                return { ...x, bool: false }
            }
        })
        setListBool(updatebools);
    }


    return (

        < Navbar collapseOnSelect expand="lg" variant='dark' className='nav-bar' fixed='top' >
            <Container className=' nav-containers'>
                <Navbar.Brand className='ms-2'><img src={logoimg2x} className='logo-img ps-2' alt="Moaisus-logo" onClick={() => history("/")} /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" className='me-3' />
                <Navbar.Collapse id="responsive-navbar-nav" className='me-3 nv-respo-colapse ' >
                    <Nav className="mx-auto">
                        {listbool.map((data, key) => {
                            return (
                                <NavLink key={key} to={`${data?.link}`} onClick={(e) => activenavlink(e, key)} className={data.bool ? "active-navList px-3 " : "nav-list px-3"} style={{ textDecoration: "none" }} >{data?.name}</NavLink>
                            )
                        })}
                    </Nav>
                    <Nav className='nav-talk  px-2 px-sm-3 px-md-3 pxs-lg-0 px-xl-0'>
                        <Navbar.Text className='nv-letstalk ps-3 pe-5' onClick={() => setModalShow(!modalShow)}>
                            Let's Talk
                            <img src={ltIcon} alt="let's talk icon" className='lticon' />
                        </Navbar.Text>
                        <ContactUs show={modalShow} onHide={() => setModalShow(false)} />
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar >
    )
}

export default Navbars;
