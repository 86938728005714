/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import "../css/contactus.css";
import facebook from "../assets/Moaisus-assets/Home/Social Media/Facebook@2x.png";
import linkedin from "../assets/Moaisus-assets/Home/Social Media/Linked In@2x.png";
import whatsapp from "../assets/Moaisus-assets/Home/Social Media/Whatsapp@2x.png";
import twitter from "../assets/Moaisus-assets/Home/Social Media/Tweeter@2x.png";

function SocialMedia() {
  return (
    <div className="icon-bar">
      <a href="https://www.linkedin.com/company/moaisus/" className=" linkedin" target="_blank">
        <img src={linkedin} alt="" className="icon-img" />
      </a>
      <a href="https://wa.me/+918884726337" className="whatsapp" target="_blank">
        <img src={whatsapp} alt="" className="icon-img" />
      </a>

    </div>
  );
};
export default SocialMedia;