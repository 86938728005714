import React, { useEffect } from 'react';
import AboutUsImage from '../commons/AboutUsImage';
import OurTeam from '../commons/OurTeam';

function AboutUs() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <AboutUsImage />
            <OurTeam />
        </div>
    )
}

export default AboutUs