import React from 'react'
import '../../css/Component.css';
import headingimg from "../../assets/images/Headings.png";
import image from "../../assets/Infra/download (1).jpg";
import image1 from "../../assets/Infra/download (2).jpg";
import image2 from "../../assets/Infra/download (3).jpg";
import image3 from "../../assets/Infra/download (4).jpg";
import image4 from "../../assets/Infra/download (5).jpg";
import image5 from "../../assets/Infra/download (6).jpg";
import image6 from "../../assets/Infra/download (2).jpg";
import image7 from "../../assets/Infra/images (1).jpg";
import image8 from "../../assets/Infra/images (2).jpg";
import image9 from "../../assets/Infra/images (3).jpg";
import image10 from "../../assets/Infra/images (4).jpg";
import image11 from "../../assets/Infra/images (5).jpg";
import image12 from "../../assets/Infra/images.jpg";
import image13 from "../../assets/Infra/download.jpg";



import { Col, Row } from 'react-bootstrap';

export default function InfraContaint() {

    const arr = [
        {
            image1: image,
            text1: "Data Center Transformation / Migration Design and Deployment of Network Infrastructure Rack and Stack of Hardware ( Router / Switches / Servers Etc ) Wired & Wireless Networks.",
            image2: image12,
            text2: "System and high performance Cables, Structured Network Cabling Solutions."
        },
        {
            image1: image7,
            text1: "Infrastructure Documentations and feasibility reports.",
            image2: image6,
            text2: "Site Infrastructure audit/recommendation reports."
        },
        {
            image1: image2,
            text1: "Network & VOIP Cabling & Installation Services.",
            image2: image5,
            text2: "CAT / Fiber optic Cabling."
        },
        {
            image1: image4,
            text1: "Design, Plan and Project Management.",
            image2: image10,
            text2: "We can perform cabling installations in inducted and non-ducted premises."
        },
        {
            image1: image3,
            text1: "Structured cabling service for building back-bone connections.",
            image2: image13,
            text2: "Moves, adds and changes to your existing cabling infrastructure."
        },
        {
            image1: image8,
            text1: "Cable patching and patch panel installations. Data Rack Power installations and Maintenance.",
            image2: null,
            text2: ""
        }
    ]

    const arrServiceSector = [
        "Media",
        "Telecom",
        "Banking",
        "Hospitality",
        "Healthcare"
    ]

    return (
        <div className='our-team-container mx-auto mx-5 mb-5' >
            <div className='about-heading pt-5 mx-5'>
                <div className='d-flex align-items-center'>
                    <img src={headingimg} alt="" />
                    <h2 className='primaryColor m-0 mx-4'>Infrastructure services</h2>
                </div>

                <p className='fw-bold mt-4'>
                    Moaisus Infrastructure services offers a detailed menu of Infrastructure Services in major cities in India. We offer all stacks of Hardware, OS, Applications and Networks support'.our experts engineers in  all Layer 1/2/3 Infrastructure are trained for small to large enterprise data centers,Offers End-to-End Solutions from consultation to implementation of enterprise solutions.Standard and Custom engineered cabling products and solutions, Our approach maximizes the performance of connectivity for next generation speeds and technologies also execute data center assessment / survey. Installation of all  infrastructure components  including central patching location, patch panels and zone solutions including on-site support for a wide range of services / tasks.
                </p>
            </div>

            {/* <div className='about-heading pt-5 mx-5'>
                <div className='d-flex align-items-center'>
                    <img src={headingimg} alt="" />
                    <h2 className='primaryColor m-0 mx-4'>Rolling Slide</h2>
                </div>
                <p className='fw-bold mt-4'>
                    Structured Cabling Solutions - Data Center Transformation - IT Support / Operations - Hardware Installations/Upgrades - On site support
                </p>
            </div> */}

            <div className='about-heading pt-5 mx-5'>
                <div className='d-flex align-items-center'>
                    <img src={headingimg} alt="" />
                    <h2 className='primaryColor m-0 mx-4'>Serving Sectors</h2>
                </div>
                {arrServiceSector.map((item, i) => (
                    <p className='fw-bold mt-4' key={i}>
                        &#x2022; {item}
                    </p>
                ))}


            </div>

            <div>
                <div className='about-heading pt-5 mx-5'>
                    <div className='d-flex align-items-center'>
                        <img src={headingimg} alt="" />
                        <h2 className='primaryColor m-0 mx-4'>Services</h2>
                    </div>
                </div>


                {arr.map((item, i) =>
                (<div key={i}>
                    <Row className="justify-content-md-center mt-5">
                        <Col sm={4}>
                            <img
                                src={item?.image1}
                                width={'120%'}
                                alt="custom gifts illustration"
                                className='mx-auto d-block op-content-imgs'
                            />
                        </Col>
                        <Col sm={6} className="justify-content-md-center align-self-center">
                            <div className="m-3 text-start fw-bold " >
                                {item?.text1}
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mt-5">
                        <Col sm={6} className="justify-content-md-center align-self-center">
                            <div className="m-3 text-start fw-bold" >
                                {item?.text2}
                            </div>
                        </Col>
                        <Col sm={4}>
                            <img
                                src={item?.image2}
                                width={'120%'}
                                alt=""
                                className='mx-auto d-block op-content-imgs'
                            />
                        </Col>
                    </Row>
                </div>)
                )}
            </div>




        </div>
    )
}

