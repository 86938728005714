import React, { useEffect } from "react";
import "../css/Component.css";
import { useLocation } from "react-router-dom";
import ContactUs from "../commons/ContactUs";
import Footer from "../commons/Footer";
import OurProducts from "../commons/OurProducts";
import Slider from "../commons/Slider";
import SocialMedia from "../commons/SocialMedia";
import Tech from "../commons/Tech";
import Testimonial from "../commons/Testimonial";
import Whatwedo from "../commons/Whatwedo";
import Trusted from "../commons/trusted";
import Workhighlight from "../commons/Workhighlight";

function LandingPage() {

  const { pathname, hash, key } = useLocation();
  useEffect(() => {

    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <div className="hide-scroll">
      <SocialMedia />

      <ContactUs />
      <Slider />
      <OurProducts />
      <Trusted />
      <Tech />
      <Whatwedo />
      <div id={"portfolio"}>
        <Workhighlight />
      </div>
      <Testimonial />
      <Footer />
    </div>
  );
}

export default LandingPage;
